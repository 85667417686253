import { Message, Error } from "types/common"
import { Event, EventID } from "types/events"

/***********************
   ATTENDANCE TRACKING
************************/
export interface AttendanceTrackingState {
  isLoading: boolean
  error: Error
  events: Record<EventID, boolean>
}

export enum AttendanceTrackingActionType {
  CLEAR_ATTENDANCE_TRACKING_STATE = "@@personalization:attendance-tracking/CLEAR_ATTENDANCE_TRACKING_STATE",

  MARK_USER_AS_ATTENDED_FOR_EVENT = "@@personalization:attendance-tracking/MARK_USER_AS_ATTENDED_FOR_EVENT",
  MARK_USER_AS_ATTENDED_FOR_EVENT_SUCCESS = "@@personalization:attendance-tracking/MARK_USER_AS_ATTENDED_FOR_EVENT_SUCCESS",
  MARK_USER_AS_ATTENDED_FOR_EVENT_FAILURE = "@@personalization:attendance-tracking/MARK_USER_AS_ATTENDED_FOR_EVENT_FAILURE",

  FETCH_ALL_USER_ATTENDED_EVENTS = "@@personalization:attendance-tracking/FETCH_ALL_USER_ATTENDED_EVENTS",
  FETCH_ALL_USER_ATTENDED_EVENTS_SUCCESS = "@@personalization:attendance-tracking/FETCH_ALL_USER_ATTENDED_EVENTS_SUCCESS",
  FETCH_ALL_USER_ATTENDED_EVENTS_FAILURE = "@@personalization:attendance-tracking/FETCH_ALL_USER_ATTENDED_EVENTS_FAILURE",
}

// GENERIC ATTENDANCE TRACKING
export interface IClearAttendanceTrackingState {
  type: AttendanceTrackingActionType.CLEAR_ATTENDANCE_TRACKING_STATE
}
export type GenericAttendanceTrackingAction = IClearAttendanceTrackingState

// SELF REPORT ATTENDANCE
export interface IMarkUserAsAttendendedForEvent {
  type: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT
}
export interface IMarkUserAsAttendendedForEventSuccess {
  type: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_SUCCESS
  data: Event
}
export interface IMarkUserAsAttendendedForEventFailure {
  type: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_FAILURE
  error: Error
}
export type MarkUserAsAttendedForEventAction =
  | IMarkUserAsAttendendedForEvent
  | IMarkUserAsAttendendedForEventSuccess
  | IMarkUserAsAttendendedForEventFailure

// FETCH ALL ATTENDED EVENTS
export interface IFetchAllUserAttendedEvents {
  type: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS
}
export interface IFetchAllUserAttendedEventsSuccess {
  type: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_SUCCESS
  data: Event[]
}
export interface IFetchAllUserAttendedEventsFailure {
  type: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_FAILURE
  error: Error
}
export type FetchAllUserAttendedEventsAction =
  | IFetchAllUserAttendedEvents
  | IFetchAllUserAttendedEventsSuccess
  | IFetchAllUserAttendedEventsFailure

export type AttendanceTrackingAction =
  | GenericAttendanceTrackingAction
  | MarkUserAsAttendedForEventAction
  | FetchAllUserAttendedEventsAction

/***********************
      FAVORITES
************************/

export interface FavoritesState {
  isLoading: boolean
  error: Error
  events: Record<EventID, boolean>
}

export enum FavoritesActionType {
  USER_FAVORITES_EVENT = "@@favorites/USER_FAVORITES_EVENT",
  USER_FAVORITES_EVENT_SUCCESS = "@@favorites/USER_FAVORITES_EVENT_SUCCESS",
  USER_FAVORITES_EVENT_FAILURE = "@@favorites/USER_FAVORITES_EVENT_FAILURE",

  USER_UNFAVORITES_EVENT = "@@favorites/USER_UNFAVORITES_EVENT",
  USER_UNFAVORITES_EVENT_SUCCESS = "@@favorites/USER_UNFAVORITES_EVENT_SUCCESS",
  USER_UNFAVORITES_EVENT_FAILURE = "@@favorites/USER_UNFAVORITES_EVENT_FAILURE",

  CLEAR_FAVORITES_STATE = "@@favorites/CLEAR_FAVORITES_STATE",
}

export interface IClearFavoritesState {
  type: FavoritesActionType.CLEAR_FAVORITES_STATE
}
export type GenericFavoritesAction = IClearFavoritesState
export interface IUserFavoritesEvent {
  type: FavoritesActionType.USER_FAVORITES_EVENT
}
export interface IUserFavoritesEventSuccess {
  type: FavoritesActionType.USER_FAVORITES_EVENT_SUCCESS
  data: Message
}
export interface IUserFavoritesEventFailure {
  type: FavoritesActionType.USER_FAVORITES_EVENT_FAILURE
  error: Error
}
export type FavoriteEventAction = IUserFavoritesEvent | IUserFavoritesEventSuccess | IUserFavoritesEventFailure
export interface IUserUnfavoritesEvent {
  type: FavoritesActionType.USER_UNFAVORITES_EVENT
}
export interface IUserUnfavoritesEventSuccess {
  type: FavoritesActionType.USER_UNFAVORITES_EVENT_SUCCESS
  data: Message
}
export interface IUserUnfavoritesEventFailure {
  type: FavoritesActionType.USER_UNFAVORITES_EVENT_FAILURE
  error: Error
}
export type UnfavoriteEventAction = IUserUnfavoritesEvent | IUserUnfavoritesEventSuccess | IUserUnfavoritesEventFailure

export type FavoritesAction = GenericFavoritesAction | FavoriteEventAction | UnfavoriteEventAction
