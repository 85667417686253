import * as R from "ramda"
// import initialState from "models/initialState"
import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"
// import { Error } from "models/utils"
import { Actions as uiActions } from "models/ui"
// import { GenericAuthAction, AuthActionType } from "models/auth"
// import { Event, EventID, FetchEventAction, EventsActionType, selectCurrentEvent } from "models/events"

import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { ToastType } from "types/ui"
import { AuthAction, AuthActionType } from "types/auth"
import {
  EventID,
  EventFull,
  SingleEventSuccessAction,
  MultipleEventsSuccessAction,
  EventsActionType,
} from "types/events"
import {
  ProvidersActionType,
  ProviderFull,
  SingleProviderSuccessAction,
  MultipleProvidersSuccessAction,
} from "types/providers"
import {
  AttendanceTrackingState,
  AttendanceTrackingAction,
  AttendanceTrackingActionType,
  MarkUserAsAttendedForEventAction,
  FetchAllUserAttendedEventsAction,
} from "types/personalization"

const createStateUpdateForMultipleEvents = (state: AttendanceTrackingState, events: EventFull[]) => {
  return {
    ...state,
    events: {
      ...state.events,
      ...R.mapObjIndexed(
        (v: EventFull) => Boolean(v.user_reported_attending),
        R.indexBy((e) => R.prop("id", e), events)
      ),
    },
  }
}

const updateStateAfterSuccessfullyFetchingSingleEvent = (
  state: AttendanceTrackingState,
  action: SingleEventSuccessAction
) => {
  return createStateUpdateForMultipleEvents(state, [action.data as EventFull])
}

const updateStateAfterSuccessfullyFetchingMultipleEvents = (
  state: AttendanceTrackingState,
  action: MultipleEventsSuccessAction
) => {
  return createStateUpdateForMultipleEvents(state, action.data as EventFull[])
}

const updateStateAfterSuccessfullyFetchingProvider = (
  state: AttendanceTrackingState,
  action: SingleProviderSuccessAction
) => {
  const events = (action.data as ProviderFull).events
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: AttendanceTrackingState,
  action: MultipleProvidersSuccessAction
) => {
  const events = R.flatten(R.map((provider) => provider.events as EventFull[], action.data as ProviderFull[]))
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

/**
 * ATTENDANCE TRACKING REDUCER
 */
export default function attendanceTrackingReducer(
  state = initialState.attendanceTracking,
  action:
    | AttendanceTrackingAction
    | AuthAction
    | SingleEventSuccessAction
    | MultipleEventsSuccessAction
    | MultipleProvidersSuccessAction
    | SingleProviderSuccessAction
) {
  switch (action.type) {
    /* PROVIDER ACTIONS */
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleProviders(state, action)
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)

    /* EVENT ACTIONS */
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)
    case EventsActionType.FETCH_EVENT_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleEvent(state, action)
    case EventsActionType.FETCH_USER_EVENTS_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)

    // case EventsActionType.FETCH_EVENT_SUCCESS:
    //   return {
    //     ...state,
    //     events: {
    //       ...state.events,
    //       [action.data.id]: action.data.user_reported_attending,
    //     },
    //   }
    case AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT:
      return {
        ...state,
        isLoading: true,
      }
    case AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        events: {
          ...state.events,
          [action.data.id]: true,
        },
      }
    case AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS:
      return {
        ...state,
        isLoading: true,
      }
    case AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        events: {
          ...state.events,
          ...(action.data.reduce((acc, event) => {
            acc[event.id] = true
            return acc
          }, {}) || {}),
        },
      }
    case AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case AuthActionType.SIGN_USER_OUT:
      return initialState.attendanceTracking
    default:
      return state
  }
}

/**
 * ATTENDANCE TRACKING ACTIONS
 */

export type MarkUserAsAttendedForEventResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  MarkUserAsAttendedForEventAction
>
const markUserAsAttendedForEvent = ({ eventId }: { eventId: EventID }): MarkUserAsAttendedForEventResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        // url: `/events/${eventId}/development_plans/`,
        url: `/personalization/attendance-tracking/${eventId}/`,
        method: "POST",
        types: {
          REQUEST: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT,
          SUCCESS: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_SUCCESS,
          FAILURE: AttendanceTrackingActionType.MARK_USER_AS_ATTENDED_FOR_EVENT_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          const error = res?.error?.response && res?.error?.response?.data ? res.error.response.data : res.error

          const errorMessages = extractErrorMessages(error)

          errorMessages.forEach((error) => {
            const toast = {
              // type: "danger",
              type: ToastType.DANGER,
              title: "Unable to record attendance for this event.",
              contents: makeUserFriendlyErrorMessage(error),
              link: error === EMAIL_NEEDS_CONFIRMATION_MESSAGE ? `/profile/` : null,
            }
            dispatch(uiActions.addUiToast({ toast }))
          })

          return { success: false, status: res.status, error }
        },
      })
    )
  }
}

export type FetchAllUserAttendedEventsResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FetchAllUserAttendedEventsAction
>
const fetchAllUserAttendedEvents = (): FetchAllUserAttendedEventsResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/personalization/attendance-tracking/`,
        method: "GET",
        types: {
          REQUEST: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS,
          SUCCESS: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_SUCCESS,
          FAILURE: AttendanceTrackingActionType.FETCH_ALL_USER_ATTENDED_EVENTS_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => {
          const failureToast = {
            // type: "danger",
            type: ToastType.DANGER,
            title: "Unable to complete request.",
            contents: `Something went wrong. Please contact support.`,
          }
          dispatch(uiActions.addUiToast({ toast: failureToast }))

          return { success: false, status: res.status, error: res.error }
        },
      })
    )
  }
}

export const Actions = {
  markUserAsAttendedForEvent,
  fetchAllUserAttendedEvents,
}

/**
 * ATTENDANCE TRACKING SELECTORS
 */

export const selectHasUserMarkedEventAsAttended = (state: StateShape, eventId: string) => {
  return R.either(
    () => R.path(["attendanceTracking", "events", eventId], state),
    () => R.path(["events", "data", "user_reported_attending"], state)
  )()
}

// export const getHasUserMarkedCurrentEventAsAttended = (state: StateShape) => {
//   return R.path(["attendanceTracking", "events", selectCurrentEvent(state)?.id], state)
// }

// export const selectHasUserMarkedCurrentEventAsAttended = createSelector(
//   [getHasUserMarkedCurrentEventAsAttended],
//   (userHasAttended) => Boolean(userHasAttended)
// )
