import * as R from "ramda"
// import { createSelector } from "reselect"
import { ThunkAction } from "redux-thunk"
import apiClient, { ApiClientCallbackResponse } from "services/apiClient"

import initialState, { StateShape } from "models/initialState"

// import { extractErrorMessages, makeUserFriendlyErrorMessage } from "utils/errors"
// import { EMAIL_NEEDS_CONFIRMATION_MESSAGE } from "constants/errors"

import { ToastType } from "types/ui"
import { AuthAction, AuthActionType } from "types/auth"
import {
  EventID,
  EventFull,
  SingleEventSuccessAction,
  MultipleEventsSuccessAction,
  EventsActionType,
} from "types/events"
import {
  ProvidersActionType,
  ProviderFull,
  SingleProviderSuccessAction,
  MultipleProvidersSuccessAction,
} from "types/providers"
import { FavoritesState } from "types/personalization"

import { FavoritesAction, FavoritesActionType, FavoriteEventAction, UnfavoriteEventAction } from "types/personalization"

// TODO: HANDLE SITUATION WHERE MULTIPLE EVENTS ARE FETCHED OR PROVIDERS ARE FETCHED WITH EVENTS ON THEM
const createStateUpdateForMultipleEvents = (state: FavoritesState, events: EventFull[]) => {
  return {
    ...state,
    events: {
      ...state.events,
      ...R.mapObjIndexed(
        (v: EventFull) => Boolean(v.user_has_favorited),
        R.indexBy((e) => R.prop("id", e), events)
      ),
    },
  }
}

const updateStateAfterSuccessfullyFetchingSingleEvent = (state: FavoritesState, action: SingleEventSuccessAction) => {
  return createStateUpdateForMultipleEvents(state, [action.data as EventFull])
}

const updateStateAfterSuccessfullyFetchingMultipleEvents = (
  state: FavoritesState,
  action: MultipleEventsSuccessAction
) => {
  return createStateUpdateForMultipleEvents(state, action.data as EventFull[])
}

const updateStateAfterSuccessfullyFetchingProvider = (state: FavoritesState, action: SingleProviderSuccessAction) => {
  const events = (action.data as ProviderFull).events
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

const updateStateAfterSuccessfullyFetchingMultipleProviders = (
  state: FavoritesState,
  action: MultipleProvidersSuccessAction
) => {
  const events = R.flatten(R.map((provider) => provider.events as EventFull[], action.data as ProviderFull[]))
  return createStateUpdateForMultipleEvents(state, events as EventFull[])
}

/**
 * FAVORITES REDUCER
 */

export default function favoritesReducer(
  state = initialState.favorites,
  action:
    | FavoritesAction
    | AuthAction
    | SingleEventSuccessAction
    | MultipleEventsSuccessAction
    | MultipleProvidersSuccessAction
    | SingleProviderSuccessAction
) {
  switch (action.type) {
    /* PROVIDER ACTIONS */
    case ProvidersActionType.FETCH_PROVIDERS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleProviders(state, action)
    case ProvidersActionType.FETCH_PROVIDER_SUCCESS:
      return updateStateAfterSuccessfullyFetchingProvider(state, action)

    /* EVENT ACTIONS */
    case EventsActionType.FETCH_EVENTS_FROM_SLUG_LIST_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)
    case EventsActionType.FETCH_EVENT_SUCCESS:
      return updateStateAfterSuccessfullyFetchingSingleEvent(state, action)
    case EventsActionType.FETCH_USER_EVENTS_SUCCESS:
      return updateStateAfterSuccessfullyFetchingMultipleEvents(state, action)

    // case EventsActionType.FETCH_EVENT_SUCCESS:
    //   return {
    //     ...state,
    //     events: {
    //       ...state.events,
    //       [action.data.id]: action.data.user_has_favorited,
    //     },
    //   }

    case FavoritesActionType.USER_FAVORITES_EVENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FavoritesActionType.USER_FAVORITES_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        events: {
          ...state.events,
          [action.data.data.event_id]: true,
        },
      }
    case FavoritesActionType.USER_FAVORITES_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case FavoritesActionType.USER_UNFAVORITES_EVENT:
      return {
        ...state,
        isLoading: true,
        error: null,
      }
    case FavoritesActionType.USER_UNFAVORITES_EVENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        events: {
          ...state.events,
          [action.data.data.event_id]: false,
        },
      }
    case FavoritesActionType.USER_UNFAVORITES_EVENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      }
    case FavoritesActionType.CLEAR_FAVORITES_STATE:
      return initialState.favorites
    case AuthActionType.SIGN_USER_OUT:
      return initialState.favorites
    default:
      return state
  }
}

/**
 * FAVORITES ACTIONS
 */
export type FavoriteOrUnfavoriteEventParams = { eventId: EventID }
export type FavoriteOrUnfavoriteEventResult = ThunkAction<
  Promise<ApiClientCallbackResponse>,
  StateShape,
  void,
  FavoriteEventAction | UnfavoriteEventAction
>
const userFavoritesEvent = ({ eventId }: FavoriteOrUnfavoriteEventParams): FavoriteOrUnfavoriteEventResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/${eventId}/favorites/`,
        method: "POST",
        types: {
          REQUEST: FavoritesActionType.USER_FAVORITES_EVENT,
          SUCCESS: FavoritesActionType.USER_FAVORITES_EVENT_SUCCESS,
          FAILURE: FavoritesActionType.USER_FAVORITES_EVENT_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => ({ success: false, status: res.status, error: res.error }),
      })
    )
  }
}

const userUnfavoritesEvent = ({ eventId }: FavoriteOrUnfavoriteEventParams): FavoriteOrUnfavoriteEventResult => {
  return (dispatch) => {
    return dispatch(
      apiClient({
        url: `/events/${eventId}/favorites/`,
        method: "DELETE",
        types: {
          REQUEST: FavoritesActionType.USER_UNFAVORITES_EVENT,
          SUCCESS: FavoritesActionType.USER_UNFAVORITES_EVENT_SUCCESS,
          FAILURE: FavoritesActionType.USER_UNFAVORITES_EVENT_FAILURE,
        },
        options: {
          data: {},
          params: {},
        },
        onSuccess: (res) => ({ success: true, status: res.status, data: res.data }),
        onFailure: (res) => ({ success: false, status: res.status, error: res.error }),
      })
    )
  }
}

export const Actions = {
  userFavoritesEvent,
  userUnfavoritesEvent,
}

/**
 * Favorites Selectors
 */

export const selectUserHasFavoritedAttended = (state: StateShape, eventId: EventID): boolean => {
  return (
    Boolean(R.path(["favorites", "events", eventId], state)) ||
    Boolean(R.path(["events", "data", "user_has_favorited"], state))
  )
}

// export const getHasUserFavoritedCurrentEvent = (state: StateShape): boolean => {
//   const currentEventId = selectCurrentEvent(state)?.id
//   return R.prop(currentEventId, state.favorites.events)
// }
// export const selectHasUserFavoritedCurrentEvent = createSelector(
//   [getHasUserFavoritedCurrentEvent],
//   (userHasFavorited) => Boolean(userHasFavorited)
// )
